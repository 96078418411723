import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const MainPoint = ({
	title,
	subtitle,
	imageSrc,
	imageXsSrc,
	isImageXs,
	imageAlt,
	textColor,
	bgColor,
	height,
}) => {
	return (
		<div
			className={`apl-mainpoint ${isImageXs ? 'long' : ''}`}
			style={{
				color: textColor,
				backgroundColor: bgColor,
				height: height,
			}}
		>
			<div className='apl-mainpoint--container'>
				<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
				<p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
				<GatsbyImage
					className={`apl-mainpoint--image is-hidden-widescreen`}
					image={imageSrc}
					alt={imageAlt}
				/>
				{isImageXs ? (
					<GatsbyImage
						className={`apl-mainpoint--image is-hidden-until-widescreen`}
						image={imageXsSrc}
						alt={imageAlt}
					/>
				) : (
					''
				)}
			</div>
		</div>
	)
}

MainPoint.displayName = 'MainPoint'

MainPoint.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	imageSrc: PropTypes.object,
	imageXsSrc: PropTypes.object,
	isImageXs: PropTypes.bool,
	textColor: PropTypes.string,
	bgColor: PropTypes.string,
	height: PropTypes.string,
}

MainPoint.defaultProps = {
	title: null,
	subtitle: null,
	imageSrc: null,
	imageXsSrc: null,
	isImageXs: null,
	textColor: null,
	bgColor: null,
	height: null,
}

export default MainPoint
