import React, { PureComponent, createRef } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

class Topbar extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			translate: 0,
			toggle: false,
			show: false,
			showProduct: false,
			showLang: false,
			hash: props.location?.hash,
		}

		this.container = createRef()
		this.handleScroll = this.handleScroll.bind(this)
		this.handleToggle = this.handleToggle.bind(this)
		this.handleShow = this.handleShow.bind(this)
		this.handleShowProduct = this.handleShowProduct.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleShowLang = this.handleShowLang.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, false)
		document.addEventListener('mousedown', this.handleClose)
	}

	componentDidUpdate(_, prevState) {
		if (prevState.toggle === this.state.toggle) {
			if (prevState.show === this.state.show && prevState.showProduct === this.state.showProduct) {
				this.setState({
					toggle: false,
				})
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
		document.removeEventListener('mousedown', this.handleClose)
	}

	handleScroll() {
		this.setState({
			translate: window.pageYOffset,
		})
	}

	handleToggle() {
		this.setState((prevState) => ({
			toggle: !prevState.toggle,
		}))
	}

	handleShow() {
		this.setState((prevState) => ({
			show: !prevState.show,
			showProduct: false,
			showLang: false,
		}))
	}

	handleShowProduct() {
		this.setState((prevState) => ({
			showProduct: !prevState.showProduct,
			show: false,
			showLang: false,
		}))
	}

	handleShowLang() {
		this.setState((prevState) => ({
			showLang: !prevState.showLang,
			show: false,
			showProduct: false,
		}))
	}

	handleClose(event) {
		if (this.container.current && !this.container.current.contains(event.target)) {
			this.setState({
				show: false,
				showProduct: false,
				showLang: false,
			})
		}
	}

	render() {
		const { location, imageSrc, imageAlt, hash, setHash } = this.props
		const { translate, toggle, show, showProduct, showLang } = this.state

		return (
			<div className={`apl-topbar ${toggle ? 'apl-topbar--active' : ''} ${translate > 1 ? 'apl-topbar--show' : ''}`}>
				<div className='container' ref={this.container}>
					<div className='apl-topbar--wrapper'>
						<div className='apl-topbar--brand'>
							<Link className='logo-warung-pintar' to='/en'>
								<GatsbyImage image={getImage(imageSrc)} alt={imageAlt} />
							</Link>
						</div>
						<div role='button' tabIndex='0' className='apl-topbar--toggle' aria-label='button-toggle' onClick={() => this.handleToggle()} onKeyDown={() => this.handleToggle()}>
							<span />
							<span />
							<span />
						</div>
						<div className={`apl-topbar--menu ${toggle ? 'apl-topbar--menu--show' : ''}`}>
							<ul>
								<div className='apl-topbar--menu--dropdown'>
									<button type='button' className={`nav-solusi-kami apl-topbar--menu--active`} onClick={this.handleShow}>
										Our Solutions
										<span />
									</button>
									<div className={`apl-topbar--menu--dropdown--content ${show ? 'apl-topbar--menu--dropdown--content--show' : ''}`}>
										<Link className='nav-juragan-warung' to='/en/juragan-warung/'>
											Juragan Warung & Kelontong
										</Link>
										<Link className='nav-juragan-grosir' to='/en/juragan-grosir/'>
											Juragan Grosir
										</Link>
										<Link className='nav-distributor-fmcg' to='/en/distributor-fmcg/'>
											Distributor FMCG
										</Link>
										<Link className='nav-brand-and-supplier' to='/en/brand-supplier/'>
											Brand & Supplier
										</Link>
									</div>
								</div>
								<li>
									<Link className='nav-tentang' activeClassName='apl-topbar--menu--active' to='/en/tentang-perusahaan/'>
										About
									</Link>
								</li>
								<li>
									<Link className='nav-karir' activeClassName='apl-topbar--menu--active' to='/en/karir/'>
										Career
									</Link>
								</li>
								<li>
									<Link className='nav-blog' activeClassName='apl-topbar--menu--active' to='/blog/'>
										Blog
									</Link>
								</li>
								<li>
									<a className='apl-topbar--menu--button' href='https://warungpintar.onelink.me/GNek/WPGNAV' target='_blank' rel='nofollow noopener noreferrer'>
										Install Now
									</a>
								</li>
								{
									<div className='apl-topbar--menu--dropdown'>
										<button type='button' className={`apl-topbar--menu--active`} onClick={this.handleShowLang}>
											EN
											<span />
										</button>
										<div className={`apl-topbar--menu--dropdown--content ${showLang ? 'apl-topbar--menu--dropdown--content--show' : ''}`}>
											<Link className='nav-ina-indonesia' to={location.pathname.substring(3)}>
												ID
											</Link>
										</div>
									</div>
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Topbar.displayName = 'Topbar'

export default Topbar
