import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const ServiceCardItem = ({
	title,
	subtitle,
	subtitleMobile,
	imageSrc,
	imageXsSrc,
	imageAlt,
}) => {
	return (
		<div className='apl-servicecard--item'>
			<h5 dangerouslySetInnerHTML={{ __html: title }}></h5>
			<p
				className='is-hidden-widescreen'
				dangerouslySetInnerHTML={{ __html: subtitle }}
			></p>
			<p
				className='is-hidden-until-widescreen'
				dangerouslySetInnerHTML={{ __html: subtitleMobile }}
			></p>
			<GatsbyImage
				className={`apl-servicecard--item--image is-hidden-widescreen`}
				image={imageSrc}
				alt={imageAlt}
			/>
			<GatsbyImage
				className={`apl-servicecard--item--image is-hidden-until-widescreen`}
				image={imageXsSrc}
				alt={imageAlt}
			/>
		</div>
	)
}

ServiceCardItem.displayName = 'ServiceCardItem'

ServiceCardItem.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	subtitleMobile: PropTypes.string,
	imageSrc: PropTypes.object,
	imageXsSrc: PropTypes.object,
	imageAlt: PropTypes.string,
}

ServiceCardItem.defaultProps = {
	title: null,
	subtitle: null,
	subtitleMobile: null,
	imageSrc: null,
	imageXsSrc: null,
	imageAlt: null,
}

export default ServiceCardItem
