import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import facebookIcon from '../../images/icons/facebook-icon.svg'
import instagramIcon from '../../images/icons/instagram-icon.svg'
import twitterIcon from '../../images/icons/twitter-icon.svg'
import linkedinIcon from '../../images/icons/linkedin-icon.svg'
import addressIcon from '../../images/icons/address-icon.svg'
import whatsappIcon from '../../images/icons/whatsapp-icon.svg'
import emailIcon from '../../images/icons/email-icon.svg'

const Footbar = ({ imageSrc, imageAlt }) => {
	return (
		<div className='apl-footbar'>
			<div className='container'>
				<div className='apl-footbar--brand'>
					<GatsbyImage image={getImage(imageSrc)} alt={imageAlt} />
				</div>
				<div className='apl-footbar--list'>
					<ul className='is-hidden-touch'>
						<h5>Company</h5>
						<li>
							<Link to='/en/'>Home</Link>
						</li>
						<li>
							<Link to='/en/tentang-perusahaan/'>About</Link>
						</li>
						<li>
							<Link to='/en/karir/'>Career</Link>
						</li>
						<li>
							<Link to='/en/privacy-policy/'>Privacy Policy</Link>
						</li>
					</ul>
					<ul className='is-hidden-touch'>
						<h5>Our solutions</h5>
						<li>
							<Link to='/en/juragan-warung/'>Warung Owners</Link>
						</li>
						<li>
							<Link to='/en/juragan-grosir/'>Wholesaler</Link>
						</li>
						<li>
							<Link to='/en/distributor-fmcg/'>FMCG Distributors</Link>
						</li>
						<li>
							<Link to='/en/brand-supplier/'>Brands and Suppliers</Link>
						</li>
					</ul>
					<ul>
						<h5>Contact Us</h5>
						<li>
							<img src={addressIcon} alt='address' />
							<p>Jl. Bumi No.40, RW.3, Gunung, Kec. Kby. Baru, Jakarta Selatan, 12120</p>
						</li>
						<li>
							<img src={emailIcon} style={{ marginTop: '4px' }} alt='email' />
							<p>
								<a href='mailto:partnership@warungpintar.co' target='_blank' rel='nofollow noopener noreferrer'>
									partnership@warungpintar.co
								</a>
							</p>
						</li>
						<h5>CS Warung Pintar</h5>
						<li>
							<img src={whatsappIcon} alt='whatsapp' />
							<p>+62 851-5757-7630</p>
						</li>
					</ul>
					<ul className='is-hidden-touch'>
						<h5>Layanan Pengaduan Konsumen</h5>
						<li>
							<p>Direktorat Jendral Perlindungan Konsumen dan Tertib Niaga Kementrian Perdagangan Republik Indonesia</p>
						</li>
						<br />
						<li>
							<img src={whatsappIcon} alt='whatsapp' />
							<p>+62 853-1111-1010</p>
						</li>
					</ul>
				</div>
				<div className='apl-footbar--copyright'>
					<ul>
						<li>
							<p>©2021 by Warung Pintar. All Right Reserved</p>
						</li>
					</ul>
					<ul>
						<li>
							<a href='https://www.facebook.com/warungpintar.co.id/' target='_blank' rel='nofollow noopener noreferrer'>
								<img src={facebookIcon} alt='facebook-icon' />
							</a>
						</li>
						<li>
							<a href='https://www.instagram.com/warungpintar.co/' target='_blank' rel='nofollow noopener noreferrer'>
								<img src={instagramIcon} alt='instagram-icon' />
							</a>
						</li>
						<li>
							<a href='https://twitter.com/warungpintarco/' target='_blank' rel='nofollow noopener noreferrer'>
								<img src={twitterIcon} alt='twitter-icon' />
							</a>
						</li>
						<li>
							<a href='https://www.linkedin.com/company/warungpintar/' target='_blank' rel='nofollow noopener noreferrer'>
								<img src={linkedinIcon} alt='linkedin-icon' />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

Footbar.displayName = 'Footbar'

Footbar.propTypes = {
	imageSrc: PropTypes.object,
	imageAlt: PropTypes.string,
}

Footbar.defaultProps = {
	imageSrc: null,
	imageAlt: null,
}

export default Footbar
