import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Topbar from '../topbar/Topbar.en'
import Footbar from '../footbar/Footbar.en'
import FixedFooter from '../fixed-footer/FixedFooter'

import '../../styles/reset.scss'
import '../../styles/global.scss'
import '../../styles/main.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-modal/styles.css'

const Layout = ({ children, logo, categories, location, hash, setHash }) => {
	return (
		<>
			<Helmet>
				<script
					charset='UTF-8'
					src='//web.webpushs.com/js/push/ccb02ee733da6160792864bb2aa41965_1.js'
					async
				></script>
			</Helmet>
			<Topbar
				imageSrc={logo}
				imageAlt='warung-pintar-group-logo'
				location={location}
				hash={hash}
				setHash={setHash}
				categories={categories}
			/>
			{children}
			<Footbar
				imageSrc={logo}
				imageAlt='warung-pintar-group-logo'
				location={location}
			/>
			<FixedFooter location={location} />
		</>
	)
}

Layout.displayName = 'Layout'

Layout.propTypes = {
	children: PropTypes.any,
}

Layout.defaultProps = {
	children: null,
}

export default Layout
