import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout.en'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import BusinessModel from '../components/business-model/BusinessModel'
import BusinessModelItem from '../components/business-model/BusinessModelItem'
import ContactForm from '../components/contact-form/ContactForm'
import ServiceCard from '../components/service-card/ServiceCard'
import ServiceCardItem from '../components/service-card/ServiceCardItem'
import MainPoint from '../components/main-point/MainPoint'
import TrackingAnalytics from '../components/tracking-analytics/TrackingAnalytics'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const BizzyFieldForce = ({ data, location }) => {
	const [hash, setHash] = useState(location?.hash)
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout
			location={location}
			logo={data.logoImage}
			setHash={setHash}
			hash={hash}
		>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content:
							seoData.opengraphImage !== null
								? seoData.opengraphImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content:
							seoData.twitterImage !== null
								? seoData.twitterImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='slide-banner'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem
							key={index}
							title={itemData.title}
							content={itemData.content}
							buttonLabel={itemData.buttonLabel}
							buttonLink={itemData.buttonLink}
							logoSrc={getImage(itemData.logoSrc.localFile)}
							imageSrc={getImage(itemData.imageSrc.localFile)}
							imageXsSrc={getImage(itemData.imageXsSrc.localFile)}
							withButton={itemData.withButton}
							withLogo={true}
							logoSlim={true}
							bgPosition='center bottom'
						/>
					))}
				</Slide>
			</section>

			<section className='service-card'>
				<MainPoint
					title='Bizzy Field Force: Digital Sales Assistant that helps salesman'
					subtitle='Bizzy Field Force app is designed as digital assistant that helps salesman do their job smartly. Equipped with smart features such task manager, incentive monitoring, and advance algorithms to generate data that helps salesman understand retailers better.'
					imageSrc={getImage(data.mainPoint.nodes[0])}
					imageAlt='wpg-banners'
					textColor='#FFFFFF'
					bgColor='#006CCA'
					height='700px'
				/>
			</section>

			<section className='service-card' id='manfaat'>
				<ServiceCard title='Support salesman main task in 1 app'>
					<ServiceCardItem
						title='Daily Visits'
						subtitle='Conduct more sales, billing, surveys, <br /> inventory data collection, and receipt of <br /> returned goods from consumer'
						subtitleMobile='Conduct more sales, billing, surveys, inventory data collection, and receipt of returned goods from consumer'
						imageSrc={getImage(data.serviceCard.nodes[0])}
						imageXsSrc={getImage(data.serviceCard.nodes[3])}
						imageAlt='wpg-illustration'
					/>
					<ServiceCardItem
						title='Register new store <br /> to be consumer '
						subtitle='Access to register new opened stores <br /> directly connected to NOO portal'
						subtitleMobile='Access to register new opened stores directly connected to NOO portal'
						imageSrc={getImage(data.serviceCard.nodes[1])}
						imageXsSrc={getImage(data.serviceCard.nodes[4])}
						imageAlt='wpg-illustration'
					/>
					<ServiceCardItem
						title='Additional Visits'
						subtitle='Visits outside the route or plan, <br /> such as consumer request'
						subtitleMobile='Visits outside the route or plan, such as consumer request'
						imageSrc={getImage(data.serviceCard.nodes[2])}
						imageXsSrc={getImage(data.serviceCard.nodes[5])}
						imageAlt='wpg-illustration'
					/>
				</ServiceCard>
			</section>

			<section className='business-model'>
				<BusinessModel
					title='More than just visits'
					bgImageSrc={getImage(data.businessModel.nodes[3])}
				>
					<BusinessModelItem
						title='Maintain <br /> good relationship with consumers'
						imageSrc={getImage(data.businessModel.nodes[4])}
						imageAlt='wpg-illustrator'
						isBFF={true}
					/>
					<BusinessModelItem
						title='Liase distributors <br /> directly to consumers'
						imageSrc={getImage(data.businessModel.nodes[5])}
						imageAlt='wpg-illustrator'
						isBFF={true}
					/>
				</BusinessModel>
			</section>

			<section className='service-card'>
				<MainPoint
					title="Track consumer's <br /> order and information completely"
					subtitle="Possess the visibility of consumer's <br /> information, order history, <br /> order status, and credit status "
					imageSrc={getImage(data.mainPoint.nodes[1])}
					imageXsSrc={getImage(data.mainPoint.nodes[2])}
					isImageXs={true}
					imageAlt='wpg-banners'
					textColor='#000000'
					bgColor='#FFEFBB'
					height='800px'
				/>
			</section>

			<section className='tracking-analytics' id='fitur'>
				<TrackingAnalytics
					title='Salesman performance analytic <br /> tracking integrated with <br /> <span class="highlight">Bizzy Connect</span>'
					titleMobile='Salesman <br /> performance analytic tracking integrated with <br /> <span class="highlight">Bizzy Connect</span>'
					subtitle1='BFF Daily Monitor'
					text1='Track overall salesforce performance, such as locations, visits, and performance'
					subtitle2='Performance Review'
					text2='Review salesforce performance daily or monthly to help them achieve the target'
					imageSrc={getImage(data.trackingAnalytics.nodes[0])}
					imageXsSrc={getImage(data.trackingAnalytics.nodes[1])}
					imageAlt='wpg-banners'
				/>
			</section>

			<section className='contact-form'>
				<ContactForm
					title='Interested with BFF?'
					subtitle="Contact us! We'll help you improve your business!"
					endpoint='https://getform.io/f/e403cc8a-9b83-4ef4-8af7-cd86c7a99c78'
					lang={true}
					isBFF={true}
					imageSrc={getImage(data.contactBackgrounds.nodes[2])}
					imageXsSrc={getImage(data.contactBackgrounds.nodes[6])}
					maxWidth={true}
				/>
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "bizzy-field-force-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					title
					withButton
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1600)
							}
						}
					}
				}
			}
		}
		mainPoint: allFile(
			filter: { relativeDirectory: { eq: "banners/main-point" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1600)
				}
			}
		}
		serviceCard: allFile(
			filter: { relativeDirectory: { eq: "illustrations/services-card" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1240)
				}
			}
		}
		businessModel: allFile(
			filter: {
				relativeDirectory: { eq: "illustrations/business-model" }
			}
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		trackingAnalytics: allFile(
			filter: { relativeDirectory: { eq: "banners/tracking-analytics" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1240)
				}
			}
		}
		contactBackgrounds: allFile(
			filter: { relativeDirectory: { eq: "illustrations/contact-forms" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default BizzyFieldForce
