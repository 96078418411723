import React from 'react'
import PropTypes from 'prop-types'

const ServiceCard = ({ children, title }) => {
	return (
		<div className='apl-servicecard'>
			<div className='container'>
				<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
				{children}
			</div>
		</div>
	)
}

ServiceCard.displayName = 'ServiceCard'

ServiceCard.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
}

ServiceCard.defaultProps = {
	children: null,
	title: null,
}

export default ServiceCard
