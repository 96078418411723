import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const BusinessModelItem = ({ title, subtitle, imageSrc, imageAlt, isBFF }) => {
	return (
		<div className={`apl-business-model--item ${isBFF ? 'bff' : ''}`}>
			<GatsbyImage className='image' image={imageSrc} alt={imageAlt} />
			<div className='content'>
				<h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
				<p>{subtitle}</p>
			</div>
		</div>
	)
}

BusinessModelItem.displayName = 'BusinessModelItem'

BusinessModelItem.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	imageSrc: PropTypes.object,
	imageAlt: PropTypes.string,
	isBFF: PropTypes.bool,
}

BusinessModelItem.defaultProps = {
	title: null,
	subtitle: null,
	imageSrc: null,
	imageAlt: null,
	isBFF: null,
}

export default BusinessModelItem
