import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const TrackingAnalytics = ({
	title,
	titleMobile,
	imageSrc,
	imageXsSrc,
	imageAlt,
	subtitle1,
	text1,
	subtitle2,
	text2,
}) => {
	return (
		<div className={`apl-trackinganalytics`}>
			<div className='apl-trackinganalytics--container'>
				<h2
					class='is-hidden-widescreen'
					dangerouslySetInnerHTML={{ __html: title }}
				></h2>
				<h2
					class='is-hidden-until-widescreen'
					dangerouslySetInnerHTML={{ __html: titleMobile }}
				></h2>
				<div className='apl-trackinganalytics--image'>
					<GatsbyImage
						className={`apl-trackinganalytics--image is-hidden-widescreen`}
						image={imageSrc}
						alt={imageAlt}
					/>
					<GatsbyImage
						className={`apl-trackinganalytics--image is-hidden-until-widescreen`}
						image={imageXsSrc}
						alt={imageAlt}
					/>
					<div>
						<h3>{subtitle1}</h3>
						<p>{text1}</p>
					</div>
					<div>
						<h3>{subtitle2}</h3>
						<p>{text2}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

TrackingAnalytics.displayName = 'TrackingAnalytics'

TrackingAnalytics.propTypes = {
	title: PropTypes.string,
	titleMobile: PropTypes.string,
	imageSrc: PropTypes.object,
	imageXsSrc: PropTypes.object,
	imageAlt: PropTypes.string,
	subtitle1: PropTypes.string,
	text1: PropTypes.string,
	subtitle2: PropTypes.string,
	text2: PropTypes.string,
}

TrackingAnalytics.defaultProps = {
	title: null,
	titleMobile: null,
	imageSrc: null,
	imageXsSrc: null,
	imageAlt: null,
	subtitle1: null,
	text1: null,
	subtitle2: null,
	text2: null,
}

export default TrackingAnalytics
